import React from 'react'
import Title from 'components/Title'
import Container from 'components/Container'
// import ExternalLink from 'components/ExternalLink'
import Footer from "components/Footer"
import Header from "components/Header"
import { useTranslation } from 'gatsby-plugin-react-i18next'
// import { useSiteMetadata } from 'hooks/useSiteMetadata'
import { DateTime } from 'luxon'

export default () => {

    const { t } = useTranslation()

    // const { title } = useSiteMetadata()

    return (
        <>
        <Header/>
        <main className='main-content'>
            <Title dark title={t('Terms and Conditions')} subtitle={`${ t(`Updated:`) } ${ DateTime.fromISO('2020-12-07').toLocaleString(DateTime.DATE_FULL) }`} />

            {/* <Container>
                <iframe 
                    src="https://cdn.forms-content.sg-form.com/8e4ae6f6-2a8a-11eb-a1d1-4e330953a988" 
                    frameBorder='0'
                    width='100%'
                    height="544"
                    title='contact-us'
                />
            </Container> */}

            <Container>

                <h2>{ t(`1. Access`) }</h2>

                <p>
                    {t(`terms-access`, `Subject to the acceptance of the terms and conditions of this Agreement by Client, including the payment of applicable fees, DS Media ("DSM") hereby grants Client a non-exclusive, non-transferable License to access and use the Service on a month to month basis only, during the term of this Agreement. Client may only use the Service for Clients own internal business purposes, and not in the operation of a service bureau or for the benefit of any other person or entity. The Agreement is for the provision of Service based on DSM programming code, not for the provision of the programming code, and the programming code is not being delivered or licensed hereunder. All rights not specifically granted to Client hereunder are retained by DSM.`)}
                </p>

            </Container>

            <Container>

                <h2>{ t(`2. Service Fees and Payment`) }</h2>

                <p>
                    { t(`terms-serviceFeesAndPayment`, `Client will pay DSM for the Service in the amount agreed upon from time to time and set forth in the Agreement by way of automatic monthly credit card payments by Client to DSM. Payments will be authorized/deducted approx. the first of every month and such payment(s) provides to Client access to the Service for the then current month ending the last day of each month. Service will automatically renew for the next month is payment is made in advance as described. If Client fails to pay the fees in accordance with the foregoing terms, Client's access to the Service shall immediately terminate following the last day of the month for which payment has been made. The prices and rates for the Service do not include applicable federal or provincial taxes and Client agrees to pay any applicable taxes based on their province of residence or business operation. Promptly upon expiration or termination of this Agreement for any reason, Client shall pay any unpaid or outstanding fees due to DSM that may have accrued as of the date of expiration or termination.`) }
                </p>

                <h3>{ t(`2.1 Accepting Online/Mobile Payment Fees`) }</h3>

                <p>
                    { t(`terms-acceptingOnlineAndMobilePaymentsFees`, `Client may choose to use the Service to accept online payments from their retail customers. DSM uses ©Stripe as a 3rd party secure payment processor. ©Stripe is a registered mark. To use the payment processing services of Stripe, Client must first register their own account with Stripe and agree to Stripe's Terms and Conditions of use including the payment of processing fees as described in those Terms of Use.  All transactions processed through the Service by Stripe will include an additional $0.30 per transaction administrative fee charged by DSM.`) }
                </p>

                <h3>{ t(`2.2 Use of Cellular Data`) }</h3>

                <p>
                    { t(`terms-useOfCellularData`, `There are no additional charge to Client for the use of cellular data in connection with the delivery of the Service by DSM. Service includes unlimited text messaging between Client and their retail customers however; does not include voice or data for any other purposes whatsoever. Client understands and agrees that the mobile cellular number used in the connection with the delivery of the Service is owned by DSM and client has no right, title or ownership interest in the number whatsoever. Client will be responsible for any data or overage charges that are incurred as a result of usage from other than the intended purposes of providing two-way SMS communication between Client and their retail customers.`) }
                </p>

            </Container>

            <Container>

                <h2>{ t(`3. Confidential Treatment`) }</h2>

                <h3>{ t(`3.1 Confidential Information`) }</h3>

                <p>
                    { t(`terms-confidentialInformation`, `In connection with the Agreement, each party may disclose, or may learn of or have access to, certain confidential proprietary information owned by the other party ("Confidential Information"). Confidential Information means any data or information, oral or written, that relates to a party, or any of its business activities, technology, developments, inventions, processes, trade secrets, know how, source code, plans, financial information, customer and supplier lists, forecasts, and projections. For the avoidance of doubt, Customer data (as defined below) is the Confidential Information of Customer and the Service is the Confidential Information of the Licensor DSM. Notwithstanding the foregoing, Confidential Information is deemed not to include information that: (i) is publicly available or in the public domain at the time disclosed; (ii) is or becomes publicly available or enter the public domain through no fault of the receiving part (iii) is rightfully communicated to the receiving part by persons not bound by confidentiality obligations with respect thereto; (iv) is already in the receiving parts possession free of any confidentiality obligations with respect thereto; (v) can be documented as independently developed by a party without use of any Conferential Information of the other party or (vi) is approved for release or disclosure by the disclosing party without restriction.`) }
                </p>

                <h3>{ t(`3.2 Obligations`) }</h3>

                <p>
                    { t(`terms-obligations`, `Except as expressly permitted in the Agreement, each party shall maintain the Confidential Information of the other party in strict confidence and shall not disclose, publish or copy any part of such Confidential Information. Each party shall use the Confidential Information of the other party solely for the purpose of the Agreement. Each party shall take all necessary precautions in handling the Confidential Information of the other party and limit disclosures on a strict need to know basis. However, a party may disclose Confidential Information of the other party pursuant to the order or requirement of a court, administrative agency, or other governmental body, provided that such party gives reasonable prior notice to the other party to contest such order or requirement. Upon request or termination of this Agreement, each party shall return to the other party, or certify the destruction of, all Confidential Information of the other party, provided that neither party shall be obligated to purge any data that has been archived, provided that the terms of Section 5 are otherwise observed.`) }
                </p>

            </Container>

            <Container>

                <h2>{ t(`4. Compliance with Laws`) }</h2>

                <p>
                    { t(`terms-compliance`, `Both parties agree to comply with all federal, provincial and local laws, rules and regulation applicable to each party's receipt and use of data provided to the other. DSM reserves the right to revise the terms or conditions under the Agreement and/or the Service (including without limitation the right to withdraw or restrict affected data) to meet any requirement imposed by federal, provincial or local law, rule or regulation, or to address matters concerning privacy and confidentiality, upon reasonable notice to Client.`) }
                </p>

            </Container>

            <Container>

                <h2>{ t(`5. Ownership`) }</h2>

                <p>
                    { t(`terms-ownership`, `DSM shall at all times be, and remain, the sole and exclusive owner of all intellectual property rights in the Service including, without limitation, the underlying software and DSM's trademarks used in relation to the Service. The Agreement does not transfer any right, title, or interest in the Service to Client. Except for the rights expressly granted to Client in the Agreement, Client does not hold, and will not seek to assert, any right, title or interest in the Service. As between the parties, except as otherwise set forth in a writing signed by the parties, all right, title and interest in any improvements, enhancements or other modifications of Service including any improvements, enhancements or modifications created, suggested or requested by Client, after the Effective Date, shall be solely owned exclusively by DSM.`) }
                </p>

            </Container>

            <Container>

                <h2>{ t(`6. Customer Data`) }</h2>

                <p>
                    { t(`terms-customerData`, `Client owns and shall retain all rights in and to their own Customer Data. DSM shall provide Client with electronic access to their Customer Data in a manner to be mutually agreed. DSM does not acquire any ownership interested in the Customer Data by virtue of providing the Service. Notwithstanding the foregoing, Client hereby grants DSM a non-exclusive, perpetual, irrevocable, worldwide license to use and adapt the Customer Data (a) to provide the Service, and (b) to provide network and Service analytics and data to other customers, potential customers and the public, so long as such analytics and data do not specifically identity a customer or breach any manner of Privacy Act then in effect.`) }
                </p>

            </Container>

            <Container>

                <h2>{ t(`7. Warranty and Disclaimers`) }</h2>

                <p>
                    { t(`terms-warrantyAndDisclaimers`, `DSM warrants to Client that DSM will use commercially reasonable efforts to perform the Service in a timely manner. Because the Service involves conveying information provided to DSM by other sources, DSM cannot and will not, for the fee charged for the Service, be an insurer or guarantor of the accuracy or reliability of the Service. EXCEPT FOR ALL THE LIMITED WARRANTY SET FORTH IN THE FIRST SENTENCE OF THIS PARAGRAPH, THE SERVICE AND ANY SUPPORT SERVICES ARE PROVIDED TO CUSTOMER "AS IS" AND DSM EXPRESSLY DISCLAIMS ALL WARRANTIES, EXPRESS, IMPLIED OR STATUTORY, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANT ABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING, USAGE OR TRADE. DSM DOES NOT WARRANT THAT THE SERVICE WILL MEET CLIENT'S SPECIFIC REQUIREMENTS OR THAT THE OPERATION OF THE SERVICE WILL BE COMPLETELY ERROR-FREE OR UNINTERRUPTED. DSM FURTHER EXPRESSLY DISCLAIMS ANY EXPRESS OR IMPLIED WARRANTIES WITH RESPECT TO THE ACCURACY, COMPLETENESS OR CORRECTNESS OF ANY DATA OR ANY OTHER MATERIALS (TANGIBLE OR INTANGIBLE) SUPPLIED BY DSM HEREUNDER AND DSM SHALL NOT BE LIABLE TO CLIENT FOR ANY INOPERABILITY OF THE SERVICE OR FOR ANY LOSS OF INFORMATION OR OTHER INJURY, DAMAGE OR DISRUPTION OF ANY KIND. ANY USE BY CLIENT OF THE SERVICE IS AT THE CLIENTS OWN RISK. THE LIMITED WARRANTY IN THE FIRST SENTENCE OF THIS PARAGRAPH IS THE ONLY WARRANTY PROVIDED BY DSM REGARDING THE SERVICE AND THE AGREEMENT.`) }
                </p>

            </Container>

            <Container>

                <h2>{ t(`8. Limitation and Liability`) }</h2>

                <p>
                    { t(`terms-limitationAndLiability`, `IN NO EVENT WILL EITHER PARTY BE LIABLE TO OTHER PARTY FOR ANY SPECIAL, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES (INCLUDING WITHOUT LIMITATION LOSS OF USE, DATA, BUSINESS OR PROFITS OR COSTS OF COVER) ARISING OUT OF OR IN CONNECTION WITH THE AGREEMENT OR THE USE OR PERFORMANCE OF THE SERVICE, WHETHER SUCH LIABILITY ARISES FROM ANY CLAIM BASED UPON CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR OTHERWISE, AND WHETHER OR NOT SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE. EITHER PARTY'S CUMULATIVE LIABILITY TO THE OTHER PARTY, FROM ALL CAUSES OF ACTION AND ALL THEORIES OF LIABILITY, WILL BE LIMITED TO AND WILL NOT EXCEED THE FEES PAID TO DSM BY CLIENT DURING ANY MONTH OF THIS AGREEMENT.`) }
                </p>

            </Container>

            <Container>

                <h2>{ t(`9. Waiver`) }</h2>

                <p>
                    { t(`terms-waiver`, `Either party may waive compliance by the other party with any covenants or conditions contained in the Agreement, but only by written instrument signed by the party waiving such compliance. No such waiver, however, shall be deemed to waive any other circumstance or any other covenant or condition not expressly named in the written waiver.`) }
                </p>

            </Container>

            <Container>

                <h2>{ t(`10. Choice of Law`) }</h2>

                <p>
                    { t(`terms-choiceOfLaw`, `The Agreement is governed by and construed in accordance with the laws of the Province of Ontario, Canada.`) }
                </p>

            </Container>

            <Container>

                <h2>{ t(`11. Binding Arbitration`) }</h2>

                <p>
                    { t(`terms-bindingArbitration`, `Any dispute arising out of or relating to the Agreement shall be resolved in a binding arbitration under the auspices of the Provincial Court of Ontario.  In addition to all other rights and remedies a party may have, the prevailing party in any arbitration or legal action shall be entitled to an award of its reasonable attorneys fees and costs. This binding arbitration provision shall not, however prevent either party from seeking equitable or injunctive relief, or from pursuing an action to collect unpaid amounts due under the Agreement, in a court of competent jurisdiction.`) }
                </p>

            </Container>

            <Container>

                <h2>{ t(`12. Successors and Assigns`) }</h2>

                <p>
                    { t(`terms-siccessorsAndAssigns`, `The Agreement will be binding upon and will inure to the benefit of the parties hereto and their respective heirs, representatives, successors and permitted assignees. The Agreement may not be assigned, transferred, shared or divided in whole or in part by the Client without DSM's prior written consent.`) }
                </p>

            </Container>

            <Container>

                <h2>{ t(`13. Excusable Delays`) }</h2>

                <p>
                    { t(`terms-excusableDelays`, `Neither party shall be liable for any delay or failure in its performance under the Agreement (except for the payment of fees) if and to the extent such delay or failure is caused by events beyond the reasonable control of the party including, without limitation, acts of God, public enemies, or terrorists, labour disputes, equipment malfunctions, material or component shortages, supplier failures, embargoes, rationing, acts of local, provincial or federal governments or public agencies, utility or communication failures or delays, Internet outages, fire, earthquakes, floor, epidemics, riots and strikes. If a party becomes aware that such an event is likely to delay or prevent punctual performance of its own obligations, the party will promptly notify the other party and use its best effort to avoid or remove such causes of non-performance and to complete the delayed job whenever such causes are removed.`) }
                </p>

            </Container>

            <Container>

                <h2>{ t(`14. Notices`) }</h2>

                <p>
                    { t(`terms-notices`, `All notices, requests and other communications hereunder shall be in writing and shall be deemed delivered at the time of receipt if delivered by hand or communicated by electronic transmission, or if mailed three (3) days after mailing by first class mail with postage prepaid. Noted to DSM and Client shall be address to the addresses provided on the signature/registration page at the time of subscription to the Service, or to such other address as either party shall designate in writing to the other from time to time.`) }
                </p>

            </Container>

            <Container>

                <h2>{ t(`15. Amendments`) }</h2>

                <p>
                    { t(`terms-amendments`, `The Agreement may only be amended in writing signed by authorized representatives of both parites.`) }
                </p>

            </Container>

            <Container>

                <h2>{ t(`16. Survival`) }</h2>

                <p>
                    { t(`terms-survival`, `The provisions of Sections 2, 3, 4, 5,7, 8, 9, 10, 11, 14, and 16, in addition to any other provisions of the Agreement that would normally survive termination, shall survive termination of the Agreement for any reason.`) }
                </p>

            </Container>

            <Container>

                <h2>{ t(`17. Authority to Sign`) }</h2>

                <p>
                    { t(`terms-authorityToSign`, `The Client represents that they have all the right, power and authority to enter into this Agreement on this Agreement will be binding on Client and DSM.`) }
                </p>

            </Container>

        </main>
        <Footer/>
        </>
    )
}